<template>
    <div class="cooking-restaurant">
        <router-link class="cooking-restaurant__link cooking-restaurant__link_back link link_red"
            ref="backLink"
            v-if="userRestaurants.ids.length > 1"
            :to="{ name: 'CookingTotal' }"
        >
            Вернуться к общему времени кухни
        </router-link>
        <section class="cooking-restaurant__settings">
            <h2 class="sr-only">Настройки отчета "Время. Кухня"</h2>
            <form class="cooking__settings" @submit.prevent>
                <div class="cooking-restaurant__dateranges">
                    <daterange-picker class="cooking-restaurant__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="cooking-restaurant__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="cooking-restaurant__toggles">
                    <toggle-button class="cooking-restaurant__toggle-input"
                        :color="toggleColors"
                        v-model="modeModel"
                        :width="130"
                        :labels="modeToggleLabels"
                    />
                    <toggle-button class="cooking-restaurant__toggle-input"
                        :color="toggleColors"
                        v-model="viewModel"
                        :width="80"
                        :labels="viewToggleLabels"
                    />
                    <toggle-button class="cooking-restaurant__toggle-input"
                        v-if="viewId === 'chart'"
                        :color="toggleColors"
                        v-model="durationTypeModel"
                        :width="80"
                        :labels="durationTypeToggleLabels"
                    />
                </div>
                <div class="cooking-restaurant__selects">
                    <select class="cooking-restaurant__select"
                        v-model="serviceTypeModel"
                    >
                        <option class="cooking-restaurant__option"
                            v-for="serviceType in serviceTypes"
                            :key="serviceType.id"
                            :value="serviceType.id"
                        >
                            {{serviceType.title}}
                        </option>
                    </select>
                </div>
            </form>
        </section>
        <spinner class="cooking-restaurant__spinner" v-if="status === 'loading'" />
        <error-message class="cooking-restaurant__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Время. Кухня".
        </error-message>
        <section class="cooking-restaurant__data" v-if="status === 'success'">
            <h2 class="sr-only">Данные отчета "Время. Кухня"</h2>
            <div class="cooking-restaurant__base" v-if="modeId === 'base'">
                <div class="cooking-restaurant__box box"
                    v-if="viewId === 'table'"
                >
                    <table class="cooking-restaurant__table cooking-restaurant__table_striped cooking-restaurant__table_hover">
                        <colgroup>
                            <col class="cooking-restaurant__table-column cooking-restaurant__table-column_title">
                            <col class="cooking-restaurant__table-column">
                            <col class="cooking-restaurant__table-column">
                        </colgroup>
                        <thead>
                            <tr class="cooking-restaurant__table-row cooking-restaurant__table-row_head">
                                <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head cooking-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('title')"
                                >
                                    <div class="cooking-restaurant__table-cell-content">
                                        <span class="cooking-restaurant__table-value">Источник</span>
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'title' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'title' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                                <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head cooking-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('avg_duration')"
                                >
                                    <div class="cooking-restaurant__table-cell-content">
                                        <span class="cooking-restaurant__table-value">Средняя длительность</span>
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'avg_duration' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'avg_duration' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                                <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head cooking-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('median_duration')"
                                >
                                    <div class="cooking-restaurant__table-cell-content">
                                        <span class="cooking-restaurant__table-value">Медианная длительность</span>
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'median_duration' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="cooking-restaurant__table-value cooking-restaurant__table-value_icon"
                                            v-if="sorting.restaurant.field === 'median_duration' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <base-department-row
                                v-for="department in reportRestaurantDepartments"
                                :key="department.id"
                                :department="department"
                                :serviceTypeId="serviceTypeId"
                            />
                        </tbody>
                    </table>
                </div>
                <div class="cooking-restaurant__box cooking-restaurant__box_chart box" v-else-if="viewId === 'chart'">
                    <highcharts class="cooking-restaurant__chart" :options="cookingColumnChartOptions" :key="'cookingBaseColumnChart'" />
                </div>
            </div>
            <div class="cooking-restaurant__compare-other-period" v-else-if="modeId === 'compareOtherPeriod'">
                <p class="cooking-restaurant__error" v-if="!compareCooking">Выберите период сравнения.</p>
                <template v-else>
                    <template v-if="viewId === 'table'">
                        <div class="cooking-restaurant__box box"
                            v-for="department in reportRestaurantDepartments"
                            :key="department.id"
                        >
                            <table class="cooking-restaurant__table cooking-restaurant__table_striped cooking-restaurant__table_hover">
                                <caption class="cooking-restaurant__table-caption">{{department.title}}</caption>
                                <colgroup>
                                    <col class="cooking-restaurant__table-column cooking-restaurant__table-column_title">
                                    <col class="cooking-restaurant__table-column">
                                    <col class="cooking-restaurant__table-column">
                                </colgroup>
                                <thead>
                                    <tr class="cooking-restaurant__table-row cooking-restaurant__table-row_head">
                                        <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head" scope="col">Источник</th>
                                        <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head" scope="col">Средняя длительность</th>
                                        <th class="cooking-restaurant__table-cell cooking-restaurant__table-cell_head" scope="col">Медианная длительность</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <compare-department-primary-row
                                        :reportDaterange="reportDaterange"
                                        :department="department"
                                        :compareRestaurant="compareRestaurant"
                                        :serviceTypeId="serviceTypeId"
                                    />
                                    <compare-department-compare-row
                                        :compareDaterange="compareDaterange"
                                        :department="department"
                                        :compareRestaurant="compareRestaurant"
                                        :serviceTypeId="serviceTypeId"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <div class="cooking-restaurant__box cooking-restaurant__box_chart box"  v-else-if="viewId === 'chart'">
                        <highcharts class="cooking-detail__chart" :options="cookingColumnChartOptions" :key="'cookingCompareColumnChart'" />
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { durationFormat } from "@/helpers/filters";
    import DaterangePicker from "@/components/DaterangePicker";
    import BaseDepartmentRow from "./cooking-restaurant/BaseDepartmentRow";
    import CompareDepartmentReportRow from "./cooking-restaurant/CompareDepartmentReportRow";
    import CompareDepartmentCompareRow from "./cooking-restaurant/CompareDepartmentCompareRow";

    export default{
        name: "CookingRestaurant",
        components: {
            DaterangePicker,
            BaseDepartmentRow,
            CompareDepartmentReportRow,
            CompareDepartmentCompareRow,
        },
        props: {
            id: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            userRestaurants: {
                required: true
            },
            serviceTypes: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                modeId: state => state.cooking.modeId,
                serviceTypeId: state => state.cooking.serviceTypeId,
                durationTypeId: state => state.cooking.durationTypeId,
                viewId: state => state.cooking.viewId,
                sorting: state => state.cooking.sorting,
                data: state => state.cooking.data
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(daterange) {
                    this.setCookingCompareDaterange(daterange);
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setCookingParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            viewToggleLabels() {
                return {
                    unchecked: "Таблица",
                    checked: "График"
                };
            },
            viewModel: {
                get() {
                    return this.viewId === "chart";
                },
                set(isChart) {
                    this.setCookingParameter({
                        parameter: "viewId",
                        value: isChart ? "chart" : "table"
                    });
                }
            },
            serviceTypeModel: {
                get() {
                    return this.serviceTypeId;
                },
                set(serviceTypeId) {
                    this.setCookingParameter({
                        parameter: "serviceTypeId",
                        value: serviceTypeId
                    });
                }
            },
            durationTypeToggleLabels() {
                return {
                    unchecked: "Среднее",
                    checked: "Медиана"
                };
            },
            durationTypeModel: {
                get() {
                    return this.durationTypeId === "median_duration";
                },
                set(isMedian) {
                    this.setCookingParameter({
                        parameter: "durationTypeId",
                        value: isMedian ? "median_duration" : "avg_duration"
                    });
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredCookings() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportCooking() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            reportRestaurant() {
                if (this.id === "total") {
                    return this.reportCooking?.total;
                }

                return this.reportCooking?.restaurants?.find(restaurant => restaurant.id === this.id);
            },
            reportRestaurantDepartments() {
                return this.reportRestaurant?.departments?.sort((department1, department2) => {
                    if (this.sorting.restaurant.field === "title") {
                        return compareStrings(
                            department1.title,
                            department2.title,
                            this.sorting.direction
                        );
                    }

                    return compareNumbers(
                        department1?.[this.serviceTypeId]?.[this.sorting.restaurant.field],
                        department2?.[this.serviceTypeId]?.[this.sorting.restaurant.field],
                        this.sorting.direction
                    );
                });
            },
            compareCooking() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[daterangeKey(this.compareDaterange)];
                }
            },
            compareRestaurant() {
                if (this.id === "total") {
                    return this.compareCooking?.total;
                }

                return this.compareCooking?.restaurants?.find(({ id }) => id === this.id);
            },
            cookingColumnChartOptions() {
                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#e0121a", "#ff5261"],
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Время"
                        },
                        labels: {
                            formatter() {
                                return durationFormat(this.value);
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormatter() {
                            return `${this.series.name}: ${durationFormat(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return durationFormat(this.y);
                                }
                            }
                        }
                    },
                    series: [{
                        name: null,
                        data: []

                    }]
                };

                this.reportRestaurant?.departments?.forEach(reportDepartment => {
                    options.xAxis.categories.push(reportDepartment.title);
                    options.series[0].data.push(reportDepartment?.[this.serviceTypeId]?.[this.durationTypeId] || null);
                });

                if (this.modeId === "compareOtherPeriod" && this.compareRestaurant) {
                    options.legend.enabled = true;
                    options.series[0].name = daterangeText(this.reportDaterange);

                    options.series.push({
                        name: daterangeText(this.compareDaterange),
                        data: []
                    });

                    this.reportRestaurant?.departments?.forEach(reportDepartment => {
                        let compareDepartment = this.findDepartment(this.compareRestaurant, reportDepartment.id);
                        let compareDepartmentService = compareDepartment?.[this.serviceTypeId];

                        options.series[1].data.push(compareDepartmentService?.[this.durationTypeId] || null);
                    });
                }

                return options;
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setReportDaterange",
                "setCookingCompareDaterange",
                "setCookingParameter",
                "setCookingSorting"
            ]),
            ...mapActions([
                "requestCookings"
            ]),
            findRestaurant(cooking, restaurantId) {
                return cooking?.restaurants?.find(({ id }) => id === restaurantId);
            },
            findDepartment(restaurant, departmentId) {
                return restaurant?.departments?.find(({ id }) => id === departmentId);
            },
            setSorting(field) {
                this.setCookingSorting({ table: "restaurant", field });
            },
            showReport() {
                this.status = "success";
            },
            loadCookings() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestCookings(daterangesWithoutData).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({ name: "Error401" });
            } else {
                if (this.id === "total") {
                    this.setTitle(`${this.title} - общее`);
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} - "${restaurantTitle}"`);
                    }
                }

                if (this.requiredCookings.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadCookings();
                }
            }
        },
        watch: {
            requiredCookings(requiredCookings) {
                if (!requiredCookings.every(Boolean)) {
                    this.loadCookings();
                }
            }
        }
    }
</script>

<style lang="scss">
    .cooking-restaurant__link  {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .cooking-restaurant__settings {
        margin-bottom: 15px;
    }
    .cooking-restaurant__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-restaurant__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-restaurant__selects {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        margin-top: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .cooking-restaurant__select {
        min-width: 240px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
    .cooking-restaurant__box {
        padding: 16px;
        overflow-x: auto;

        &_chart {
            padding: 16px;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .cooking-restaurant__table {
        width: 100%;
    }
    .cooking-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .cooking-restaurant__table-column {
        &_title {
            width: 25%;
        }
    }
    .cooking-restaurant__table-row {
        &_body {
            .cooking-restaurant__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .cooking-restaurant__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .cooking-restaurant__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_baseline {
            vertical-align: baseline;
        }

        &_small {
            font-size: 16px;
        }

        &_tac {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .cooking-restaurant__table-cell-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .cooking-restaurant__table-value {
        display: block;
        line-height: 1;

        &_icon {
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }

        &_diff {
            margin-left: 8px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
