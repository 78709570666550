<template>
    <tr class="cooking-restaurant__table-row cooking-restaurant__table-row_body">
        <td class="cooking-restaurant__table-cell cooking-restaurant__table-cell_title">{{compareDaterange | daterangeText}}</td>
        <td class="cooking-restaurant__table-cell">
            <span class="cooking-restaurant__table-value">
                <template v-if="!compareRestaurant">
                    Нет ресторана
                </template>
                <template v-else-if="!compareDepartment">
                    Нет цеха
                </template>
                <template v-else-if="!compareDepartmentService">
                    Нет сервиса
                </template>
                <template v-else>
                    {{compareDepartmentServiceAverageDuration | durationFormat}}
                </template>
            </span>
        </td>
        <td class="cooking-restaurant__table-cell">
            <span class="cooking-restaurant__table-value">
                <template v-if="!compareRestaurant">
                    Нет ресторана
                </template>
                <template v-else-if="!compareDepartment">
                    Нет цеха
                </template>
                <template v-else-if="!compareDepartmentService">
                    Нет сервиса
                </template>
                <template v-else>
                    {{compareDepartmentServiceMedianDuration | durationFormat}}
                </template>
            </span>
        </td>
    </tr>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareDepartmentCompareRow",
        components: {
            Difference,
        },
        props: {
            compareDaterange: {
                type: Object,
                required: true
            },
            department: {
                type: Object,
                required: true
            },
            compareRestaurant: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            compareDepartment() {
                return this.compareRestaurant?.departments?.find(({ id }) => id === this.department.id);
            },
            compareDepartmentService() {
                return this.compareDepartment?.[this.serviceTypeId];
            },
            compareDepartmentServiceAverageDuration() {
                return this.compareDepartmentService?.avg_duration;
            },
            compareDepartmentServiceMedianDuration() {
                return this.compareDepartmentService?.median_duration;
            },
        },
        filters: {
            daterangeText
        },
    }
</script>
