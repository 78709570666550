<template>
    <tr class="cooking-restaurant__table-row cooking-restaurant__table-row_body">
        <td class="cooking-restaurant__table-cell cooking-restaurant__table-cell_title">{{reportDaterange | daterangeText}}</td>
        <td class="cooking-restaurant__table-cell">
            <div class="cooking-restaurant__table-cell-content">
                <template v-if="!departmentService">
                    Нет сервиса
                </template>
                <template v-else>
                    <span class="cooking-restaurant__table-value">{{departmentServiceAverageDuration | durationFormat}}</span>
                    <difference class="cooking-restaurant__table-value cooking-restaurant__table-value_diff"
                        v-if="compareDepartmentServiceAverageDuration"
                        :primaryValue="departmentServiceAverageDuration"
                        :compareValue="compareDepartmentServiceAverageDuration"
                        mode="percentage"
                        good="negative"
                        filter="percentFloatFormat"
                    />
                </template>
            </div>
        </td>
        <td class="cooking-restaurant__table-cell">
            <div class="cooking-restaurant__table-cell-content">
                <template v-if="!departmentService">
                    Нет сервиса
                </template>
                <template v-else>
                    <span class="cooking-restaurant__table-value">{{departmentServiceMedianDuration | durationFormat}}</span>
                    <difference class="cooking-restaurant__table-value cooking-restaurant__table-value_diff"
                        v-if="compareDepartmentServiceMedianDuration"
                        :primaryValue="departmentServiceMedianDuration"
                        :compareValue="compareDepartmentServiceMedianDuration"
                        mode="percentage"
                        good="negative"
                        filter="percentFloatFormat"
                    />
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareDepartmentReportRow",
        components: {
            Difference,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            department: {
                type: Object,
                required: true
            },
            compareRestaurant: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            departmentService() {
                return this.department?.[this.serviceTypeId];
            },
            departmentServiceAverageDuration() {
                return this.departmentService?.avg_duration;
            },
            departmentServiceMedianDuration() {
                return this.departmentService?.median_duration;
            },
            compareDepartmentService() {
                return this.compareRestaurant?.departments?.find(({ id }) => id === this.department.id)?.[this.serviceTypeId];
            },
            compareDepartmentServiceAverageDuration() {
                return this.compareDepartmentService?.avg_duration;
            },
            compareDepartmentServiceMedianDuration() {
                return this.compareDepartmentService?.median_duration;
            },
        },
        filters: {
            daterangeText
        },
    }
</script>
