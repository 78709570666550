<template>
    <tr class="cooking-restaurant__table-row cooking-restaurant__table-row_body">
        <td class="cooking-restaurant__table-cell cooking-restaurant__table-cell_title">{{department.title}}</td>
        <td class="cooking-restaurant__table-cell">
            <template v-if="departmentService">
                {{departmentServiceAverageDuration | durationFormat}}
            </template>
            <template v-else>Нет сервиса</template>
        </td>
        <td class="cooking-restaurant__table-cell">
            <template v-if="departmentService">
                {{departmentServiceMedianDuration | durationFormat}}
            </template>
            <template v-else>Нет сервиса</template>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "BaseDepartmentRow",
        props: {
            department: {
                type: Object,
                required: true
            },
            serviceTypeId: {
                type: String,
                required: true
            },
        },
        computed: {
            departmentService() {
                return this.department?.[this.serviceTypeId];
            },
            departmentServiceAverageDuration() {
                return this.department?.[this.serviceTypeId]?.avg_duration;
            },
            departmentServiceMedianDuration() {
                return this.department?.[this.serviceTypeId]?.median_duration;
            },
        }
    }
</script>
